.calculate{
    width: 100%;
    height: 100%;
    padding-top: 15px;
    overflow-x: hidden;
    background-color: rgb(255, 255, 255);
    backdrop-filter: saturate(180%) blur(10px);
    ul,li{
        list-style: none;
        list-style-type: none;
        margin: 0 auto;
        padding: 0;
        text-align: center;
        button{
            display: flex;
            align-items: center;
            margin-top: 10px;
            font-size: 17px;
            // color: white;
            .spell-icon{
                width: 20px;
            }
        }
    }
    li{
        display: flex;
        align-items: center;
        justify-content: center;
    }
}