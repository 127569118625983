.App {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .app-bg{
    position: absolute;
    top: -10%;
    left: -10%;
    width: 120%;
    height: 120%;
    background: url(./assets/images/bg.jpg) no-repeat;
    background-size: cover;
    filter: brightness(0.7) blur(5px);
    opacity: 1;
    .albumBg{
      position: absolute;
      top: -5vh;
      left: 0vw;
      width: 110vw;
      height: 110vh;
      filter: blur(15px);
      object-fit: cover;
    }
  }
  .body{
    height: calc(100vh);
    width: calc(100vw - 70px);
    left: 70px;
    overflow: hidden;
    display: flex;
    position: relative;
    .pages{
      height: calc(100vh - 100px);
      width: 100%;
      position: relative;
    }
  }
  .player{
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: 0;
  }
}
